import React, { lazy } from 'react';

const FlexposWrapper = lazy(() => import('./new/FlexposWrapper'));

const FlexposEventSaleConfig = {
	routes: [
		{
			path: '/flexpos/events',
			element: <FlexposWrapper />
		}
	]
};

export default FlexposEventSaleConfig;
