import React from 'react';

const FlexposWrapper = React.lazy(() => import('./FlexposWrapper'));

const FlexposTimeslotSaleConfig = {
	routes: [
		{
			path: '/flexpos/timeslot',
			element: <FlexposWrapper />,
		},
	],
};

export default FlexposTimeslotSaleConfig;
