import firebaseService from '@ameroservices-platform/shared/services/firebase';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import moment from 'moment-timezone';
import { setUserDataFirebase, setUserData, logoutUser } from './store/userSlice';
import { setSettings } from '../store/shared/frontendSlice';
import FuseSplashScreen from '@ameroservices-platform/attraction-flexposui/fuse/core/FuseSplashScreen';
import { hideMessage, showMessage } from '@ameroservices-platform/attraction-flexposui/app/store/fuse/messageSlice';
import { getParameterByName } from '../../AttUtils';
import organisationType from '@ameroservices-platform/shared/enums/organisationType';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

class Auth extends Component {
	state = {
		waitAuthCheck: true
	};

	componentDidMount() {
		firebaseService.init(
			success => {
				console.log('init', success);
				if (!success) {
					return Promise.resolve();
				}
				return Promise.resolve()
					.then(this.getConfig)
					.then(this.ldCheck)
					.then(ldProvider => {
						this.setState({ ldProvider });
						return this.firebaseCheck();
					})
					.then(() => {
						this.setState({ waitAuthCheck: false });
					});
			},
			undefined,
			organisationType.ATTRACTION
		);
	}

	getConfig = () =>
		new Promise(resolve => {
			fetch('/siteConfig?origin=ameroservices-platform&organisationUid=' + getParameterByName('organisationUid'))
				.then(async response => {
					const config = await response.json();
					console.log('siteConfig', config, config.organisationId, config.tenantId);

					firebaseService.setOrganisationId(config.organisationId);
					firebaseService.setTenantId(config.tenantId);
					firebaseService.setSignedSearchKey(config.signedSearchKey);

					this.props.setSettings(config);
					moment.tz.setDefault(config.timezone || 'Europe/Copenhagen');
					console.log(`moment timezone set to ${config.timezone}`);
					resolve();
				})
				.catch(e => {
					// eslint-disable-next-line no-alert
					console.error('Site not configured!', e);

					resolve();
				});

			return Promise.resolve();
		});

	firebaseCheck = () =>
		new Promise(resolve => {
			firebaseService.onAuthStateChanged(authUser => {
				if (authUser) {
					// this.props.showMessage({ message: 'Logger på...' });

					/**
					 * Retrieve user data from Firebase
					 */
					firebaseService.getUserData(authUser.uid).then(
						user => {
							if (!user.claims.organisationId) {
								firebaseService
									.callFunctionByName('authSetOrganisationOnTenantUser')
									.then(() => {
										firebaseService.refreshToken();
									})
									.catch(e => {
										console.error(e);
									});
							}
							this.props.setUserDataFirebase(user, authUser);
							// firebaseService.setSignedSearchKey(
							// 	user.claims.signedSearchKeys &&
							// 		user.claims.signedSearchKeys[
							// 			firebaseService.getOrganisationType()
							// 		]
							// 		? user.claims.signedSearchKeys[
							// 				firebaseService.getOrganisationType()
							// 		  ]
							// 		: user.claims.signedSearchKey
							// );

							resolve();
							this.props.hideMessage();
							// this.props.showMessage({ message: 'Du er nu logget ind' });
						},
						error => {
							resolve();
						}
					);
				} else {
					firebaseService.auth.signInAnonymously().then(
						authEvent => {
							resolve();
							this.props.hideMessage();
							// this.props.showMessage({ message: 'Du er nu logget ind' });
						},
						error => {
							resolve();
						}
					);
				}
			});

			return Promise.resolve();
		});

	ldCheck = async () => {
		const user = {};
		if (this.props.user) {
			user.key = this.props.user.uid;
		}
		if (!user.key) {
			delete user.key;
			user.anonymous = true;
		}
		if (this.props.user && this.props.user.data) {
			if (this.props.user.data.displayName) {
				user.name = this.props.user.data.displayName;
			}
			if (this.props.user.data.email) {
				user.email = this.props.user.data.email;
			}
		}
		if (this.props.user && this.props.user.claims) {
			user.custom = {
				organisationUid: this.props.user.claims?.organisationIds?.[organisationType.ATTRACTION] || undefined,
				role: this.props.user.claims.role || undefined,
				email_verified: this.props.user.claims.email_verified || undefined,
				mode: this.props.user.claims.mode || undefined
			};
		}
		const options = {};
		console.log('launchDarkly init with user and options: ', user, options);
		const LDProvider = await asyncWithLDProvider({
			clientSideID: process.env.REACT_APP_LD_CLIENTSIDEID || '6096f35fceeceb0de5e990c7',
			user,
			options
		});
		if (process.env.REACT_APP_LD_CLIENTSIDEID) {
			console.log('USING ANOTHER CLIENT SIDE ID: ' + process.env.REACT_APP_LD_CLIENTSIDEID);
		}
		return LDProvider;
	};

	render() {
		if (this.state.waitAuthCheck) {
			return <FuseSplashScreen />;
		}
		if (!this.state.ldProvider) {
			return <FuseSplashScreen />;
		}
		const LDComponent = this.state.ldProvider;
		return <LDComponent>{this.props.children}</LDComponent>;
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			logout: logoutUser,
			setUserData,
			setUserDataFirebase,
			showMessage,
			hideMessage,
			setSettings
		},
		dispatch
	);
}

export default connect(null, mapDispatchToProps)(Auth);
