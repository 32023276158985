import { createSlice } from '@reduxjs/toolkit';
import { showMessage } from '@ameroservices-platform/attraction-flexposui/app/store/fuse/messageSlice';
import firebaseService from '@ameroservices-platform/shared/services/firebase';
import { setUserData } from './userSlice';

export const submitLoginWithFireBase =
	({ email, password }) =>
	async (dispatch) => {
		if (!firebaseService.auth) {
			console.warn("Firebase Service didn't initialize, check your configuration");

			return () => false;
		}
		return firebaseService.auth
			.signInWithEmailAndPassword(email, password)
			.then(() => {
				return dispatch(loginSuccess());
			})
			.catch((error) => {
				const emailErrorCodes = [
					'auth/email-already-in-use',
					'auth/invalid-email',
					'auth/operation-not-allowed',
					'auth/user-not-found',
					'auth/user-disabled',
				];
				const passwordErrorCodes = ['auth/weak-password', 'auth/wrong-password'];
				const response = [];

				if (emailErrorCodes.includes(error.code)) {
					response.push({
						type: 'email',
						message: error.message,
					});
				}

				if (passwordErrorCodes.includes(error.code)) {
					response.push({
						type: 'password',
						message: error.message,
					});
				}

				if (error.code === 'auth/invalid-api-key') {
					dispatch(showMessage({ message: error.message }));
				}

				return dispatch(loginError(response));
			});
	};

const initialState = {
	success: false,
	errors: [],
};

const loginSlice = createSlice({
	name: 'auth/login',
	initialState,
	reducers: {
		loginSuccess: (state, action) => {
			state.success = true;
			state.errors = [];
		},
		loginError: (state, action) => {
			state.success = false;
			state.errors = action.payload;
		},
	},
	extraReducers: {},
});

export const { loginSuccess, loginError } = loginSlice.actions;

export default loginSlice.reducer;
