/* eslint-disable no-undef */
import { v4 as uuidv4 } from 'uuid';

class FlexposApiService {
	constructor() {
		this.callbacks = {};
		this.version = null;
		this.syncJobs = {
			barcodes: '1',
			products: '2',
			customers: '17',
			journals: '50',
			journalLines: '51'
		};
	}
	getParameterByName = (name, url = window.location.href) => {
		// eslint-disable-next-line no-useless-escape
		name = name.replace(/[\[\]]/g, '\\$&');
		const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
		const results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	};

	bindCefSharp = async (throwError = true) => {
		try {
			await CefSharp.BindObjectAsync('FlexPOS_BrowserAPIAsync');
			return true;
		} catch (e) {
			if (throwError) {
				if (process.env.NODE_ENV === 'production') {
					if (this.getParameterByName('ignoreflexposerrors') === 'true') {
						return false;
					}
					throw new Error('This page can only run on FlexPOS');
				} else {
					return false;
				}
			} else {
				console.error('This page can only run on FlexPOS');
				return false;
			}
		}
	};

	setVersion = ver => {
		this.version = ver
			.split('.')
			.map(n => +n + 100000)
			.join('.');
	};

	ifVersion = async (is, operator, ifExecute, elseExecute) => {
		const checkVersion = is
			.split('.')
			.map(n => +n + 100000)
			.join('.');

		if (operator === '>=' && this.version && this.version >= checkVersion) {
			return ifExecute(this.version);
		}
		if (operator === '<=' && this.version && this.version <= checkVersion) {
			return ifExecute(this.version);
		}
		if (operator === '>' && this.version && this.version > checkVersion) {
			return ifExecute(this.version);
		}
		if (operator === '<' && this.version && this.version < checkVersion) {
			return ifExecute(this.version);
		}
		return elseExecute(this.version);
	};

	getConfig = async (key, callback) => {
		if (callback === true) {
			return new Promise(async resolve => {
				await this.getConfig(key, d => resolve(d));
			});
		}
		await this.bindCefSharp();

		if (callback) {
			this.addCallback('getConfig', callback);
		} else {
			this.addCallback('getConfig', () => {});
		}

		FlexPOS_BrowserAPIAsync.getConfig(key).then(actualResult => {
			// const expectedResult = true;
		});
	};

	asyncGetConfig = async key => {
		return new Promise(resolve => {
			this.getConfig(key, d => resolve(d));
		});
	};

	startLabelPrint = async jsonData => {
		console.log('[FLEXSDK] Starting print of: ' + JSON.stringify(jsonData));
		await this.bindCefSharp();

		FlexPOS_BrowserAPIAsync.startLabelPrint(JSON.stringify(jsonData)).then(actualResult => {
			// const expectedResult = true;
		});
	};

	execFunction = async cmd => {
		await this.bindCefSharp();

		FlexPOS_BrowserAPIAsync.execFunction(cmd, '').then(actualResult => {
			// const expectedResult = true;
		});
	};

	addColoredText = async (text, lineType = 103, color = 1, callback) => {
		if (callback === true) {
			return new Promise(async resolve => {
				await this.addColoredText(text, lineType, color, d => resolve(d));
			});
		}
		await this.bindCefSharp();

		if (callback) {
			this.addCallback('getJournalAndLines', callback);
		} else {
			this.addCallback('getJournalAndLines', () => {});
		}

		console.log('adding line with color', color, ', type ', lineType, ' and text ', text);
		await FlexPOS_BrowserAPIAsync.addTextToJournal(text, lineType, color).then(actualResult => {
			// // const expectedResult = true;
		});
	};

	addCustomText = async (text, lineType = 1, callback) => {
		if (callback === true) {
			return new Promise(async resolve => {
				await this.addCustomText(text, lineType, d => resolve(d));
			});
		}
		await this.bindCefSharp();

		if (callback) {
			this.addCallback('getJournalAndLines', callback);
		} else {
			this.addCallback('getJournalAndLines', () => {});
		}

		console.log('adding line with type ', lineType, ' and text ', text);
		await FlexPOS_BrowserAPIAsync.addTextToJournal(text, lineType).then(actualResult => {
			// // const expectedResult = true;
		});
	};

	addProduct = async (productNumber, qty, overloadedPrice, eventUid = undefined, callback) => {
		if (callback === true) {
			return new Promise(async resolve => {
				await this.addProduct(productNumber, qty, overloadedPrice, eventUid, d => resolve(d));
			});
		}
		await this.bindCefSharp();

		if (callback) {
			this.addCallback('getJournalAndLines', callback);
		} else {
			this.addCallback('getJournalAndLines', () => {});
		}
		console.log(
			'[FLEXSDK] Adding product: ProductNumber:' +
				productNumber +
				' - Qty: ' +
				qty +
				' - OverloadedPrice: ' +
				overloadedPrice +
				' - EventUid: ' +
				eventUid
		);
		FlexPOS_BrowserAPIAsync.addProductToJournal(productNumber, qty, overloadedPrice, eventUid).then(
			actualResult => {
				// // const expectedResult = true;
			}
		);
	};

	_addProduct = async (
		productNumber,
		qty,
		overloadedPrice,
		eventUid = undefined,
		overWriteLineType = undefined,
		callback
	) => {
		await this.bindCefSharp();

		if (callback) {
			this.addCallback('getJournalAndLines', callback);
		} else {
			this.addCallback('getJournalAndLines', () => {});
		}
		await FlexPOS_BrowserAPIAsync.addProductToJournal(
			productNumber,
			qty,
			overloadedPrice,
			eventUid,
			overWriteLineType
		);
	};

	asyncAddProduct = async (
		productNumber,
		qty,
		overloadedPrice,
		eventUid = undefined,
		overWriteLineType = undefined
	) => {
		return new Promise(resolve => {
			this._addProduct(productNumber, qty, overloadedPrice, eventUid, overWriteLineType, d => resolve(d));
		});
	};

	addProductSimple = async (productNumber, qty, callback) => {
		if (callback === true) {
			return new Promise(async resolve => {
				await this.addProductSimple(productNumber, qty, d => resolve(d));
			});
		}
		await this.bindCefSharp();

		if (callback) {
			this.addCallback('getJournalAndLines', callback);
		} else {
			this.addCallback('getJournalAndLines', () => {});
		}

		FlexPOS_BrowserAPIAsync.addProductToJournal(productNumber, qty).then(actualResult => {
			// // const expectedResult = true;
		});
	};

	addText = async (text, callback) => {
		if (callback === true) {
			return new Promise(async resolve => {
				await this.addText(text, d => resolve(d));
			});
		}
		await this.bindCefSharp();

		if (callback) {
			this.addCallback('getJournalAndLines', callback);
		} else {
			this.addCallback('getJournalAndLines', () => {});
		}

		FlexPOS_BrowserAPIAsync.addTextToJournal(text).then(actualResult => {
			// // const expectedResult = true;
		});
	};

	addDiscountToJournal = async (text, amount, atIndex, callback) => {
		if (callback === true) {
			return new Promise(async resolve => {
				await this.addDiscountToJournal(text, amount, atIndex, d => resolve(d));
			});
		}
		await this.bindCefSharp();

		if (callback) {
			this.addCallback('getJournalAndLines', callback);
		} else {
			this.addCallback('getJournalAndLines', () => {});
		}
		await FlexPOS_BrowserAPIAsync.addDiscountToJournal(text, amount, atIndex);
	};

	removeJournalLine = async (uid, fallback) => {
		await this.bindCefSharp();

		FlexPOS_BrowserAPIAsync.removeJournalLine(uid).then(actualResult => {
			if (fallback) {
				fallback(actualResult);
			}
			// const expectedResult = true;
		});
	};

	addCustomer = async customerNumberOrCardnumber => {
		await this.bindCefSharp();

		FlexPOS_BrowserAPIAsync.addCustomer(customerNumberOrCardnumber).then(actualResult => {
			// const expectedResult = true;
		});
	};

	clearCustomer = async callback => {
		await this.bindCefSharp();

		FlexPOS_BrowserAPIAsync.clearCustomer().then(actualResult => {
			if (callback) {
				callback(actualResult);
			}
			// const expectedResult = true;
		});
	};

	runPOSSync = async arrayToSync => {
		await this.bindCefSharp();
		// const syncArray = arrayToSync.map((sync) => this.syncJobs[sync]).join(',');

		FlexPOS_BrowserAPIAsync.runPOSSync(arrayToSync.join(',')).then(actualResult => {
			// const expectedResult = true;
		});
	};

	startPaymentDirect = async () => {
		await this.bindCefSharp();

		FlexPOS_BrowserAPIAsync.startPaymentDirect().then(actualResult => {
			// const expectedResult = true;
		});
	};

	startPayment = async paymentTypeUID => {
		await this.bindCefSharp();

		FlexPOS_BrowserAPIAsync.startPayment(paymentTypeUID).then(actualResult => {
			// const expectedResult = true;
		});
	};

	readyToReceive = async () => {
		await this.bindCefSharp();

		FlexPOS_BrowserAPIAsync.readyToReceive().then(actualResult => {
			// const expectedResult = true;
		});
	};

	closeBrowser = async callback => {
		await this.bindCefSharp();

		FlexPOS_BrowserAPIAsync.closeBrowser().then(actualResult => {
			if (callback) {
				callback(actualResult);
			}
			// // const expectedResult = true;
		});
	};

	getVersion = async callback => {
		const success = await this.bindCefSharp(false);

		if (success) {
			this.addCallback('getVersion', callback);
			await FlexPOS_BrowserAPIAsync.getVersion();
		}
	};

	getIsSDK = async () => {
		try {
			await CefSharp.BindObjectAsync('FlexPOS_BrowserAPIAsync');
			return true;
		} catch (e) {
			return false;
		}
	};

	getSelectedCustomerInfo = async callback => {
		await this.bindCefSharp();

		this.addCallback('getSelectedCustomerInfo', callback);
		await FlexPOS_BrowserAPIAsync.getSelectedCustomerInfo();
	};

	getSelectedUserInfo = async callback => {
		await this.bindCefSharp();

		this.addCallback('getSelectedUserInfo', callback);
		await FlexPOS_BrowserAPIAsync.getSelectedUserInfo();
	};

	getPaymentTypes = async callback => {
		await this.bindCefSharp();

		this.addCallback('getPaymentTypes', callback);
		await FlexPOS_BrowserAPIAsync.getPaymentTypes();
	};

	downloadAndPrintPDF = async pathToFile => {
		await this.bindCefSharp();

		await FlexPOS_BrowserAPIAsync.downloadAndPrintPDF(pathToFile);
	};

	downloadAndPrintMultiplePDF = async arrayOfPaths => {
		await this.bindCefSharp();

		const stringOfPaths = arrayOfPaths.join(',');

		await FlexPOS_BrowserAPIAsync.downloadAndPrintPDF(stringOfPaths);
	};

	startPrint = async (printer, templateFile, copies, parameters) => {
		await this.bindCefSharp();

		console.log(
			'[FLEXSDK] Starting print of template: ' +
				templateFile +
				' on printer: ' +
				printer +
				' with ' +
				copies +
				' copies with parameters (' +
				parameters?.length +
				') ' +
				JSON.stringify(parameters)
		);
		await FlexPOS_BrowserAPIAsync.startPrint(printer, templateFile, copies, JSON.stringify(parameters));
	};

	getJournalAndLines = async (includeDeleted, callback) => {
		await this.bindCefSharp();

		this.addCallback('getJournalAndLines', callback);
		await FlexPOS_BrowserAPIAsync.getJournalAndLines(includeDeleted ? 1 : 0);
	};

	asyncGetJournalAndLines = async includeDeleted => {
		return new Promise(resolve => {
			this.getJournalAndLines(includeDeleted, data => {
				// if (data.sucess) {
				resolve(data);
				// }
			});
		});
	};

	getProduct = async (productNumberOrBarcode, callback) => {
		await this.bindCefSharp();

		this.addCallback('getProduct', callback);
		await FlexPOS_BrowserAPIAsync.getProduct(productNumberOrBarcode);
	};

	asyncGetProduct = async productNumberOrBarcode => {
		return new Promise(resolve => {
			this.getProduct(productNumberOrBarcode, d => resolve(d));
		});
	};

	onClosing = async callback => {
		const id = this.addCallback('onClosing', callback);
		return () => {
			this.removeCallback('onClosing', id);
		};
	};

	getErrorCallback = async callback => {
		await this.bindCefSharp();

		const uuid = this.addCallback('getErrorCallback', callback, true);

		return () => {
			this.removeCallback('getErrorCallback', uuid);
		};
	};

	addCallback = (type, callback) => {
		if (!this.callbacks[type]) {
			this.callbacks[type] = [];
		}
		const uuid = uuidv4();
		this.callbacks[type].push({ callback, id: uuid });
		return uuid;
	};

	removeCallback = (type, id) => {
		if (!this.callbacks[type]) {
			this.callbacks[type] = [];
		}
		const index = this.callbacks[type].findIndex(f => f.id === id);
		this.callbacks[type].splice(index, 1);
	};

	callback = (type, json, loopNotShift = false) => {
		if (!this.callbacks[type]) {
			this.callbacks[type] = [];
		}
		if (loopNotShift) {
			this.callbacks[type].forEach(callback => {
				callback.callback(json ? JSON.parse(json) : null);
			});
		} else {
			const callback = this.callbacks[type].shift();
			if (callback) {
				callback.callback(JSON.parse(json));
			}
		}
	};
}

const flexposApiService = new FlexposApiService();

const windowCallbacks = [
	{ key: 'callbackProductData', value: 'getProduct' },
	{ key: 'callbackPaymentTypes', value: 'getPaymentTypes' },
	{ key: 'callbackCustomerInfo', value: 'getSelectedCustomerInfo' },
	{ key: 'callbackUserInfo', value: 'getSelectedUserInfo' },
	{ key: 'callbackJournalAndLines', value: 'getJournalAndLines' },
	{ key: 'callbackError', value: 'getErrorCallback' },
	{ key: 'callbackVersion', value: 'getVersion' },
	{ key: 'callbackConfig', value: 'getConfig' },
	{ key: 'callbackJournalLineAdd', value: 'efrdgjh' }
	// { key: 'callbackClosing', value: 'onClosing', noShift: true }
];

windowCallbacks.forEach(pair => {
	window[pair.key] = jsonData => {
		// console.log('received callback ', pair.key, pair.value);
		flexposApiService.callback(pair.value, jsonData, pair.noShift || false);
	};
});

// window.callbackProductData = jsonData => {
// 	flexposApiService.callback('getProduct', jsonData);
// };
// window.callbackPaymentTypes = jsonData => {
// 	flexposApiService.callback('getPaymentTypes', jsonData);
// };
// window.callbackCustomerInfo = jsonData => {
// 	flexposApiService.callback('getSelectedCustomerInfo', jsonData);
// };
// window.callbackUserInfo = jsonData => {
// 	flexposApiService.callback('getSelectedUserInfo', jsonData);
// };
// window.callbackJournalAndLines = jsonData => {
// 	flexposApiService.callback('getJournalAndLines', jsonData);
// };

export default flexposApiService;
